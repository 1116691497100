import { render, staticRenderFns } from "./SampleDamageCultive.vue?vue&type=template&id=f0fb58b4"
import script from "./SampleDamageCultive.vue?vue&type=script&lang=js"
export * from "./SampleDamageCultive.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports